@import "../../../assets/scss/libs/vars";
.ant-typography.title {
  margin-bottom: 0px !important;
}
.dashboard-container {
  .report {
    margin: 0px 20px;
    margin-top: 20px;

    .report-item {
      background-color: white;
      padding: 15px 25px;
      margin: 5px 5px;
      min-width: 230px;
      width: 20%;
      border-radius: 0px 10px 10px 0px;

      .count {
        font-size: 32px;
        font-weight: 300;
        color: black;
      }

      .icon {
        margin-left: 10px;
      }
    }

    .assignments {
      color: #ffc35c;
      border-left: 5px solid #ffc35c;
    }

    .reports {
      color: #7bd65c;
      border-left: 5px solid #7bd65c;
    }

    .clients {
      color: #7e77ff;
      border-left: 5px solid #7e77ff;
    }

    .claimants {
      color: #ff7272;
      border-left: 5px solid #ff7272;
    }
  }

  .chart {
    margin: 0px 25px;

    .area-chart {
      background-color: white;
      border-radius: 10px;
      margin-right: 20px;
      height: 450px;
      min-width: 600;
      margin-top: 20px;

      .chart-header {
        min-width: 600px;
        margin: 0px auto;
        padding: 50px 50px;

        .title {
          font-size: 24px;
          color: black;
        }

        .period {
          color: #0082ff;
          font-weight: 500;
        }
      }

      .main-chart {
        margin: auto;
        width: 100%;
        height: 300px;
      }
    }

    .circle-chart {
      background-color: white;
      border-radius: 10px;
      margin-top: 20px;

      .main-chart {
        margin: 50px auto;
        margin-top: 80px;
        display: flex;
        justify-content: center;

        path:nth-child(3) {
          stroke: #83e363 !important;
        }

        .percentage {
          margin-top: 30px;
          font-size: 32px;
          color: black;
        }

        .description {
          margin-top: 10px;
          font-size: 14px;
        }
      }

      .description {
        margin: 0px auto;
        margin-bottom: 30px;
        width: 200px;

        .label {
          display: flex;
          align-items: center;
          color: black;

          .icon-1 {
            width: 13px;
            height: 13px;
            border-radius: 50%;
            margin-right: 5px;
            display: inline-block;
            background-color: #72ccff;
          }

          .icon-2 {
            width: 13px;
            height: 13px;
            border-radius: 50%;
            margin-right: 5px;
            display: inline-block;
            background-color: #83e363;
          }
        }
      }
    }
  }

  .button {
    margin: 20px 20px;

    .button-item {
      background-color: white;
      height: 200px;
      min-width: 200px;
      margin: 5px 5px;
      width: 20%;
      padding: 30px;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        .icon {
          margin: 30px auto;

          svg {
            width: 80px;
            height: 80px;
          }
        }
      }

      .icon {
        margin: 30px auto;

        svg {
          width: 70px;
          height: 70px;

          path {
            fill-opacity: 1;
          }
        }
      }

      .text {
        font-size: 16px;
      }
    }

    .assignments {
      color: #ffc35c;
      border-bottom: 5px solid #ffc35c;

      svg {
        path {
          fill: #ffc35c;
        }
      }
    }

    .reports {
      color: #7bd65c;
      border-bottom: 5px solid #7bd65c;

      svg {
        path {
          fill: #7bd65c;
        }
      }
    }

    .clients {
      color: #7e77ff;
      border-bottom: 5px solid #7e77ff;

      svg {
        path {
          fill: #7e77ff;
        }
      }
    }

    .users {
      color: #52bcff;
      border-bottom: 5px solid #52bcff;

      svg {
        path {
          fill: #52bcff;
        }
      }
    }
  }
}
