@import '../../../assets/scss/libs/vars';

.add-claimant-container {
  .main {
    margin: 20px 20px;

    .content {
      width: 100%;
      margin: auto;
      padding: 50px;
      max-width: 800px;
      background-color: white;
      border-radius: 10px;

      .main-info {
        .row {
          margin-bottom: 20px;
        }
      }

      .input-ssn {
        width: 100%;
        height: 46px;
        padding: 0px 15px;
        color: rgba(0, 0, 0, 0.65);
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        transition: all 0.3s;
        font-size: 14px;

        &:hover, &:focus, &:active {    
          border-color: $blue-color;
          box-shadow: none;
          outline: none;
        }
      }

      .checkbox-label {
        color: black;
        font-weight: 500;
      }

      .checkbox-item {
        margin: 20px 0px;
      }

      .confirm-button {
        margin-top: 30px;
      }
    }  
  }
}