@import '../../../assets/scss/libs/vars';

.resetPassword-container {
  .main-container {
    min-height: 100vh;
    max-width: 600px;
    margin: auto;

    .input-row {
      width: 100%;
      flex-wrap: nowrap;

      @media screen and (max-width: 600px) {
        flex-wrap: wrap;
      }
    }
  
    .password {
      margin: 0px 10px 10px 10px;
      box-shadow: 0px 0px 10px #EEE;

      &:hover {
        border-color: $primary-color;
      }

      &:focus {
        border-color: $primary-color;
      }
    }
  
    .reset {
      position: absolute;
      bottom: 60px;

      button {
        margin-left: 10px;
        padding: 0px;
      }
    }

    .label {
      margin: 0px 0px 50px 0px;

      @media screen and (max-width: 600px) {
        margin: 0px 0px 30px 0px;
      }
    }

    .error-text {
      padding: 10px 10px 0px 10px;
      min-height: 35px;
      width: 100%;
      max-width: 500px;

      & span::before {
        content: "*";
      }
    }
  
    .button {
      margin: 50px 0px 50px 0px;
      height: 50px;
      width: 180px;

      @media screen and (max-width: 600px) {
        margin: 30px 0px 50px 0px;
      }
    }
  
    @media screen and (max-width: 600px) {
      padding: 4em 0;
    }
  }
}