@import "../../assets/scss/libs/vars";

.msa-prescription-table-container {
  min-width: 900px;

  .table-header {
    padding: 5px 10px;
    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 10px;
    min-height: 100%;
    align-items: stretch;

    .sub-group-header {
      height: 48%;
      display: flex;
      align-items: center;
    }

    .sub-header {
      height: 50%;
      display: flex;
      align-items: stretch;
      padding-top: 3px;
    }

    .group {
      height: 100%;
      display: flex;
      align-items: stretch;
    }

    .ant-col {
      // word-break: break-all;
      text-align: center;
      padding: 3px;
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .table-content {
    margin: 0px;
    padding: 0px 10px;

    .AWP-container {
      display: flex;
      align-items: center;
    }

    .empty-icon {
      margin: 50px 0px;
    }

    .record {
      padding: 0px 0px;
      border-bottom: 1px solid #e3e4e8;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-col {
        word-break: break-word;
        text-align: center;
        font-size: 10px;
        padding: 3px;
      }

      span {
        color: black;
        text-align: center;
      }

      .text-left {
        text-align: left;
      }

      .text-center {
        text-align: center;
      }

      .text-right {
        text-align: right;
      }

      .actions {
        text-align: center;
        min-width: 100px;

        .icon {
          margin: 0px 5px;

          &:last-child {
            margin: 0px 0px;
          }
        }
      }
    }
  }

  .action {
    width: 100px;
  }

  .comment {
    flex: auto;
  }

  .border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  .border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  .border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
