.form_builder_description {
  display: flex;
}
.report_item .ant-dropdown-link {
  height: 70%;
  padding: 15px;
  background: #f2f2f2;
  color: #d8d8d8;
  width: 38%;
}

.report_item .ant-dropdown-link .anticon-down {
  float: right;
  color: #000;
}
.payment_actions_wrapper > .template_icon {
  margin-right: 15px !important;
}
.field_btn {
  background: #6f66fd !important;
  border-color: #6f66fd !important;
  border-radius: 2px !important;
  //   color: #e6e4fe !important;

  position: relative;
  left: 4%;
  margin-bottom: 25px;
}

.cancel_action_btn {
  display: flex;
  justify-content: center;
  margin: 15px 0px 15px 0px;
  color: #343434;
}

.template_edit_input {
  background: #f2f2f2;
  border-color: #f2f2f2 !important;
  border-radius: 0 !important;
}

.template_row .list-content {
  border-top: 1px solid #73738b33;
}
