@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';

body {
  font-size: 14px;
  min-width: 1024px;
}

.icon {
  cursor: pointer;
}

.header {
  height: 60px;
  background-color: white;
  width: 100%;

  .back {
    height: 100%;
    margin: auto 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 10px;
      color: #0082ff;
    }
  }

  .back-icon {
    height: 100%;
    padding: 20px 20px;
    border-right: 1px solid #e3e4e8;
  }

  .search {
    margin: 0px 15px;

    .search-box {
      width: 200px;
      height: 35px;
    }
  }    

  .title {
    width: 100%;
    position: absolute;
    text-align: center;
    right: 50px;

    .title-text {
      margin-bottom: 0;
    }
  }

  .pagination {
    margin-left: auto;
  }

  .add-button {
    margin-right: 20px;
  }

  .step {
    margin-left: auto;
    margin-right: 20px;
    font-size: 16px;
  }
}

.table-header {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 60px;
  background-color: #f7f8fa;
  min-height: 40px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.07);
}

.table-content {
  background-color: white;
  border-radius: 10px;
  margin: 20px;
  padding: 10px 20px;
}

.dropdown {
  margin-top: 10px;
  width: 100%;

  .ant-select-selection--single {
    padding: 7px 5px;
  }

  .ant-select-dropdown-menu-item {
    padding: 7px 12px;
  }
}

.date {
  width: 100%;
  
  .ant-input {
    padding: 22px;
    
    &:hover {
      border-color: 40a9ff;
    }

    &:focus {
      border-color: #40a9ff;
    }
  }
}

.empty-icon {
  margin: 200px 0px;
}

.green-btn {
  background-color: $green-color;
  border-color: $green-color;

  &:hover {
    background-color: $green-color;
    border-color: $green-color;
    opacity: 0.8;
  }

  &:active, &:focus {
    background-color: $green-color;
    border-color: $green-color;
  }
}

.pink-btn {
  background-color: $pink-color;
  border-color: $pink-color;

  &:hover {
    background-color: $pink-color;
    border-color: $pink-color;
    opacity: 0.8;
  }

  &:active, &:focus {
    background-color: $pink-color;
    border-color: $pink-color;
  }
}

.prev-button {
  color: grey;
  font-weight: 600;
  position: absolute;
  left: 30px;
}

.ant-typography {
  &.title {
    margin-bottom: 50px;
  }

  &.text {
    margin-bottom: 0px;
    color: white;
  }
}

.ant-input {
  padding: 23px;

  &:hover, &:focus, &:active {    
    border-color: $blue-color;
    box-shadow: none;
  }
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: $primary-color;
}

h1.ant-typography, .ant-typography h1 {
  @media screen and (max-width: 600px) {
    font-size: 32px;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.14);
  border-radius: 10px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin: 0px;
  padding: 25px 0px;
}

.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 25px 0px !important;
  justify-content: center;
  align-items: center;
  text-overflow: clip;
}

.ant-menu-inline-collapsed {
  width: 60px;
}

.ant-menu-inline {
  width: calc(100% - 20px);
}

.ant-input-search {
  input {
    padding: 5px 35px 5px 15px;
    background-color: #f0f1f4;
    border: none;
  }
}

.ant-select-selection--single {
  height: auto;
  padding: 7px;
}

.ant-select-dropdown-menu-item {
  padding: 10px 12px;
}

.ant-select-selection-selected-value {
  color: black;
}

.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
  box-shadow: none;
}

.ant-divider-horizontal {
  margin: 0px;
}

.ant-tabs-nav .ant-tabs-tab {
  font-size: 16px;
  font-weight: 600;
  color: black;
  margin-bottom: 5px;
}

.ant-tabs-nav-animated > div {
  display: flex;
  align-items: flex-end;
}

.ant-tabs-nav .ant-tabs-tab {
  color: black;
  font-weight: 400;
  background-color: #1890ff10;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 10px;
  height: 43px;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #1890ff;
  font-weight: 800;
  background-color: #1890ff15;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 48px;
}

.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: initial;
}

.ant-badge-count {
  box-shadow: none;
}