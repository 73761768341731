@import '../../assets/scss/libs/vars';

.medicare-cost-modal {

  .ant-modal-header {
    border-radius: 20px 20px 0px 0px;
    box-shadow: 2px 0px 5px #666;
  }

  .ant-modal-content {
    border-radius: 20px;
    background-color: #f7f8fa;
  }

  .ant-modal-title {
    text-align: center;
    font-weight: 600;
  }

  .report-body {
    flex-direction: column;
    padding: 10px 20px;

    .main-info {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}