@import '../../../assets/scss/libs/vars';

.terms-container {
  padding: 2em 3em;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  background-color: white;

  .logo {
    width: 100px;
    height: 50px;
    background: url("../../../assets/images/msa-logo-light.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .button {
    color: $primary-color;
    background-color: rgba(30, 212, 106, 0.13);
    border-color: rgba(30, 212, 106, 0.13);
    box-shadow: none;
    height: 50px;
    padding-top: 5px;
    width: 100px;

    &:hover {
      background-color: rgba(30, 212, 106, 0.13);
    }

    &:focus {
      background-color: rgba(30, 212, 106, 0.13);
    }
  }

  .content {
    margin: 3em 0;

    .title {
      font-weight: 400;

      @media screen and (max-width: 600px) {
        font-size: 28px;
        margin-bottom: 30px;
      }
    }

    .sub-title {
      margin: 20px 0px;
      font-weight: 400;
    }
  }

  .link {
    margin: 0px 30px 0px 0px;
  }
}
