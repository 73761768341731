@import '../../../../assets/scss/libs/vars';

.msa-calculation-method-container {
  .main {
    margin: 20px 20px;

    .content {
      width: 100%;
      margin: auto;
      max-width: 800px;
      background-color: white;
      border-radius: 10px;
      padding: 50px 50px;

      .title {
        font-size: 16px;
        font-weight: 600;
        color: black;
      }

      .row {
        margin: 30px 0px;
        align-items: center;
      }

      .title-row {
        flex-direction: row;
        
        .title {
          color: black;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 0px;
        }
      }

      .label {
        margin: 0px;
        width: 260px;
      }

      .input-col {
        flex: auto;
      }

      .text {
        color: black;
      }

      .dropdown {
        width: 200px;
      }

      .divider {
        margin: 40px 0px;
      }
    }  
  }
}