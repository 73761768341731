@import '../../assets/scss/libs/vars';

.auth-sidebar-container {
  min-height: 100vh;
  padding: 30px 40px;
  background-color: #161345;
  color: white;

  .logo {
    position: absolute;
    top: 50;
    width: 100px;
    height: 50px;
    background: url("../../assets/images/msa-logo.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .image {
    width: 85%;
    margin: 0 auto;
    height: 100vh;
    position: absolute;
    top: 0;
    background: url("../../assets/images/msa-auth@2x.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .copyright {
    position: absolute;
    bottom: 30px;
  
    .link {
      margin: 10px 0px;
    
      a {
        color: white;
        text-decoration: underline;
        margin-right: 20px;
      }
    }
  }
}
