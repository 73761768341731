@import '../../../assets/scss/libs/vars';

.confirmReset-container {
  .main-container {
    min-height: 100vh;
    max-width: 500px;
    margin: auto;
  
    .reset {
      position: absolute;
      bottom: 60px;

      button {
        margin-left: 10px;
        padding: 0px;
      }
    }

    .label {
      margin: 0px 0px 50px 0px;
    }

    .check-icon {
      margin: 30px;
    }

    .button {
      margin: 0px 0px 100px 0px;
      height: 50px;
      width: 150px;
      color: #0084F9;
      border-color: $blue-color;
    }
  
    @media screen and (max-width: 600px) {
      padding: 7em 0;
    }
  }
}