@import '../../../../assets/scss/libs/vars';

.msa-medicare-cost-container {
  .main {
    margin: 20px 20px;

    .content {
      width: 100%;
      margin: auto;
      background-color: white;
      border-radius: 10px;
      padding: 50px 30px;

      .title {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 600;
        color: black;
      }

      .sub-title {
        padding: 20px 0px;

        span {
          font-size: 14px;
          font-weight: 600;
          color: black;
        }
      }

      .row {
        margin-bottom: 20px;
      }

      .section {
        margin-bottom: 50px;
      }

      .divider {
        margin: 40px 0px;
      }
    }  
  }
}