@import "../../../assets/scss/libs/vars";

.reports-container {
  .header {
    .filter-icon {
      padding: 10px 7px 5px 7px;
      border: 1px solid #e3e4e8;
      border-radius: 5px;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.08);
      cursor: pointer;
    }
  }

  .table-content {
    .record {
      padding: 20px 0px;
      border-bottom: 1px solid #e3e4e8;

      &:last-child {
        border-bottom: none;
      }

      span {
        color: black;
      }

      .report-status {
        display: flex;
        align-items: center;

        .circle {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          margin-right: 10px;
          display: inline-block;
        }

        .circle-pending {
          background-color: #72ccff;
        }

        .circle-completed {
          background-color: #1ed46a;
        }

        .circle-dismissed {
          background-color: #ff7272;
        }
      }
    }
  }
}

.filter-modal {
  margin: 20px;
  color: black;

  .ant-modal-header {
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  }

  .ant-modal-content {
    border-radius: 20px;
    background-color: #f7f8fa;
  }

  .filter-header {
    padding: 5px 5px;

    .filter-reset {
      cursor: pointer;

      span {
        padding-left: 10px;
        color: #0082ff;
      }
    }

    .filter-title {
      .ant-typography {
        margin: 0px;
      }
    }

    .filter-close {
      width: 70px;
      text-align: right;
    }
  }

  .filter-body {
    flex-direction: column;

    .condition-row {
      width: 100%;
      padding: 5px 30px;
      margin-top: 10px;
    }

    .dropdown {
      width: 250px;
    }

    .input {
      width: 250px;
    }

    .date {
      margin-top: 10px;
      width: 250px;
    }

    .button {
      margin: 30px 0px 20px 0px;
      padding: 5px 30px;
    }
  }
  .row-input-wrapper {
    position: relative;
    top: -9px;
    left: 22px;
  }
}
