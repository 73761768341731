@import '../../../assets/scss/libs/vars';

.edit-client-container {
  .main {
    background-color: white;
    margin: 20px 30px;
    border-radius: 10px;

    .client-info {
      margin: 50px auto;
      padding: 0px 20px;
      max-width: 800px;

      .logo {
        flex-direction: column;
  
        .logo-image {
          width: 150px;
          height: 150px;
          margin: 0px 20px;
          background-color: #f7f8fa;
          padding: 3px;
          border: 1px solid rgba(0, 0, 0, 0.09);
        }
  
        .logo-uploader {
          margin: 20px 0px;
        }
  
        .logo-button {
          color: #0082ff;
          cursor: pointer;
        }
      }

      .content {
        flex: auto;
        padding: 0px 20px;

        .row {
          margin-bottom: 20px;
        }

        .label {
          margin-bottom: 10px;
        }

        .colors-text {
          color: black;
          font-weight: 600;
          margin-top: 20px;
        }
      }

      .primary {
        margin-right: 20px;
      }
    }

    .button {
      margin: 30px auto;
      width: 150px;
      height: 50px;
    }
  }
}
