@import '../../assets/scss/libs/vars';

.users-container {
  .table-content {
    .record {
      padding: 20px 0px;
      border-bottom: 1px solid #e3e4e8;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-child {
        border-bottom: none;
      }

      .user-column {
        display: flex;
        align-items: center;
        padding-right: 10px;
      }

      .user-name {
        margin-left: 10px;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
      }

      span {
        color: black;
        word-wrap: break-word;
      }

      .actions {
        text-align: right;

        .icon {
          margin: 0px 10px;
        }
      }
    }
  }
}

.delete-body {
  flex-direction: column;
  padding: 10px 0px;

  .trash-icon {
    margin: 20px 0px;
  }

  .label {
    color: black;
    font-size: 18px;
    font-weight: 500;
  }

  .user-info {
    border-top: 1px solid $line-color;
    border-bottom: 1px solid $line-color;
    padding: 10px 5px;
    margin: 30px auto;
    width: 300px;

    .user-name {
      margin-left: 15px;
      max-width: 200px;

      .name {
        color: black;
      }

      .text-row {
        width: 100%;
      }
    }
  }

  .button {
    width: 100px;
    height: 40px;
  }

  .cancel-button {
    padding: 20px 0px;

    span {
      color: black;
      cursor: pointer;
      text-decoration: underline;
    }      
  }
}