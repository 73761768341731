@import '../../assets/scss/libs/vars';

.input-currency-box-container {
  .label {
    margin-bottom: 10px;
  }
  
  .input {
    height: 46px;
  
    &:hover {
      border-color: #40a9ff;
    }
  
    &:focus {
      border-color: #40a9ff;
    }
  }
  
  .ant-input-number {
    padding: 7px 16px 7px 5px !important;
    width: 100%;
  
    &:hover, &:focus, &:active {    
      border-color: $blue-color;
      box-shadow: none;
    }
  }
}