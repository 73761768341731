.ant-select-selection__rendered {
  padding: 7px;
}
.ant-select-selection--multiple {
  padding-bottom: 5px;
}

.ant-select-selection__choice__content {
  padding: 8px;
  text-align: center;
  line-height: 4px;
}
