@import '../../assets/scss/libs/vars';

.label {
  margin-bottom: 10px;
}

.input {
  padding: 10px 15px !important;
  height: 46px;

  &:hover {
    border-color: #40a9ff;
  }

  &:focus {
    border-color: #40a9ff;
  }
}

.ant-input {
  padding: 23px;

  &:hover, &:focus, &:active {    
    border-color: $blue-color;
    box-shadow: none;
  }
}