// Font
$font: (
  family: ('Source Sans Pro', Helvetica, sans-serif),
  weight:	400,
  weight-bold: 400
);

// Colors
$primary-color: #00D374;
$green-color: #00D374;
$blue-color: #40a9ff;
$purple-color: #151247;
$pink-color: #ff7272;
$line-color: #73738b33;