@import '../../../assets/scss/libs/vars';

.sendRequest-container {
  .main-container {
    min-height: 100vh;
    max-width: 500px;
    margin: auto;
  
    .email {
      border-radius: 4px 4px 0px 0px;
      box-shadow: 0px 0px 10px #EEE;
  
      &:hover {
        border-color: $primary-color;
      }

      &:focus {
        border-color: $primary-color;
      }
    }
  
    .email:hover ~ .password input{
      border-top-color: white;
    }
  
    .email:focus ~ .password input{
      border-top-color: white;
    }
  
    .reset {
      position: absolute;
      bottom: 60px;

      button {
        margin-left: 10px;
        padding: 0px;
      }
    }

    .label {
      margin: 0px 0px 50px 0px;
    }

    .error-text {
      padding: 10px 10px 0px 10px;
      min-height: 55px;
      width: 100%;

      & span::before {
        content: "*";
      }
    }
  
    .button {
      margin: 30px 0px 50px 0px;
      height: 50px;
      width: 150px;
    }
  
    @media screen and (max-width: 600px) {
      padding: 5em 0;
    }
  }
}