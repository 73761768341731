@import '../../assets/scss/libs/vars';

.navigation-container {
  background-color: $purple-color;

  .logo {
    padding: 20px 20px 10px 20px;
    flex-wrap: nowrap;
    overflow: hidden;
    color: white;

    .avatar {
      background-color: $primary-color;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      margin-right: 10px;

      span {
        color: white;
        font-size: 20px;
        font-weight: 600; 
      }
    }
    
    .user-info {
      line-height: initial;
      transition-delay: 1s;
    }

    .name {
      color: white;
      width: 150px;
    }

    .email {
      color: #8c8aa7;
      width: 150px;
    }
  }

  .menu {
    background-color: $purple-color;
    color: white;
    margin: 0 auto;

    .menu-item {
      color: white;
      display: flex;
      align-items: center;
      padding-left: 5px;

      svg {
        width: 24px;
        height: 18px;
      }

      span {
        padding-left: 5px;
      }
    }
  }

  .collapse {
    position: absolute;
    bottom: 0;
    z-index: 1;
    height: 48px;
    color: #fff;
    line-height: 48px;
    text-align: center;
    background: #08053b;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    width: 100%;

    .collapse-content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .collapse-text {
      margin-left: 20px;
    }
  }

  .divider {
    margin: 15px 5px;
  }

  .text {
    padding: 10px 30px 0px 30px;

    span {
      color: #8c8aa7;
    }
  }

  .link {
    padding: 0px 30px;

    a {
      color: #8c8aa7;
      text-decoration: underline;
    }
  }

}