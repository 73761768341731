@import "../../../assets/scss/libs/vars";

.edit-template-container {
  .main {
    margin: 20px 20px;

    .content {
      width: 100%;
      margin: auto;
      max-width: 800px;
      background-color: white;
      border-radius: 10px;

      .main-info {
        margin: 50px;

        .row {
          margin-bottom: 20px;
        }
      }

      .list {
        cursor: pointer;
        border-top: 1px solid $line-color;
        border-bottom: 1px solid $line-color;
        min-height: 100px;

        .report_item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 20px 30px;
          border-bottom: 1px dashed $line-color;
          align-items: center;

          .menu-icon {
            width: 16px;
            height: 16px;
          }
        }
        .dragged .report_item {
          opacity: 0.5;
          background-color: #eee;
          border-top: 1px dashed $line-color;
          border-bottom: 1px dashed $line-color;
        }
        .floating .report_item {
          background-color: #fefefe;
          box-shadow: 0 2px 10px #999;
          border-bottom: none;
          border-top: none;
        }

        .dl-item {
          &:nth-last-child(2) {
            .report_item {
              border-bottom: none;
            }
          }
          &.floating {
            width: 85%;
          }
        }
      }

      .confirm-button {
        margin-top: 30px;
      }

      .cancel-button {
        margin: 30px auto;
        cursor: pointer;

        span {
          color: $blue-color;
        }
      }
    }
  }
}
