@import '../../assets/scss/libs/vars';

.color-button {
  height: 46px;
  width: 220px;
  margin: 10px 0px;
  display: flex;
  align-items: center;

  .color-label {
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    background-color: #0082ff;
  }

  .arrow-icon {
    margin-left: auto;
    margin-top: 3px;
  }

  span {
    margin: auto 5px;
    color: black;
  }
}

.popover {
  position: absolute;
  margin-top: -300px;
  z-index: 2;
}

.cover {
  position: fixed;
  top: -0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}