@import '../../assets/scss/libs/vars';

.delete-modal {

  .ant-modal-content {
    border-radius: 20px;
    background-color: #f7f8fa;
  }

  .delete-body {
    flex-direction: column;
    padding: 10px 0px;

    .trash-icon {
      margin: 20px 0px;
    }

    .label {
      color: black;
      font-size: 18px;
      font-weight: 500;
    }

    .content-info {
      border-top: 1px solid $line-color;
      border-bottom: 1px solid $line-color;
      padding: 10px 5px;
      margin: 30px auto;
      width: 300px;

      .content-name {
        margin-left: 15px;
        color: black;
      }
    }

    .button {
      width: 100px;
      height: 40px;
    }

    .cancel-button {
      padding: 20px 0px;

      span {
        color: black;
        cursor: pointer;
        text-decoration: underline;
      }      
    }
  }
}