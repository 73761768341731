@import "../../../../assets/scss/libs/vars";

.report-modal {
  .ant-modal-header {
    border-radius: 20px 20px 0px 0px;
    box-shadow: 2px 0px 5px #666;
  }

  .ant-modal-content {
    border-radius: 20px;
    background-color: #f7f8fa;
  }

  .ant-modal-title {
    text-align: center;
    font-weight: 600;
  }

  .report-body {
    flex-direction: column;
    padding: 20px;

    .main-info {
      width: 100%;
      margin-bottom: 40px;

      .radio-button {
        // margin-top: 10px;
        height: 46px;

        .radio {
          padding: 4px;
          float: left;
          border: 1px solid #cfcfe1;
          background-color: white;
          width: 49%;
          border-radius: 0px 4px 4px 0px;

          &:first-child {
            border-right: none;
            border-radius: 4px 0px 0px 4px;
          }

          &:last-child {
            border-radius: 0px 4px 4px 0px;
          }
        }

        .ant-radio-group {
          width: 100%;
        }

        .ant-radio-button-wrapper {
          border: none;
          width: 100%;
          text-align: center;
          height: 36px;
          color: black;
          font-size: 14px;
        }

        .ant-radio-button-wrapper-checked {
          background-color: #6f66fd;
          color: white;
        }
      }
    }
  }
}
