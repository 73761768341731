@font-face {
  font-family: "WorkSans";
  src: url(../src/assets/fonts/TTF/WorkSans-Regular.ttf) format("truetype");
}
.rdw-editor-toolbar[aria-hidden="true"] {
  display: none !important;
}
@font-face {
  font-family: "WorkSans-Thin";
  src: url(../src/assets/fonts/TTF/WorkSans-Thin.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "WorkSans" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* GLOBAL CSS */
.label {
  margin-bottom: 0px !important;
}
.ant-select-selection--multiple {
  margin-top: -10px !important;
}
.dropdown .ant-select-selection--single {
  margin-top: -10px !important;
}
