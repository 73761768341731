@import '../../assets/scss/libs/vars';

.pagination-container {
  margin: 0px 15px;

  .text {
    margin-right: 10px;
  }

  .icon {
    margin: auto 10px;
    margin-top: 7px;
  }

}