@import '../../../assets/scss/libs/vars';

.edit-user-container {
  .main {
    margin: 20px 20px;

    .content {
      width: 100%;
      margin: auto;
      padding: 50px;
      max-width: 800px;
      background-color: white;
      border-radius: 10px;

      .main-info {
        .row {
          margin-bottom: 20px;
        }
      }

      .checkbox-label {
        color: black;
        font-weight: 500;
      }

      .checkbox-item {
        margin: 20px 0px;
      }

      .confirm-button {
        margin-top: 30px;
      }
    }  
  }
}