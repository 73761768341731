.steps-content {
  width: 80%;
}

.steps-action {
  margin-top: 24px;
}

.card_input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #e8e8e8;
  background: #f2f2f2;
}

.card_input:focus {
  outline: none !important;
}

.upload_logo_wrapper {
  width: 100%;
  margin-top: 15px;
  .ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
  }
}
.secondary_action_btn {
  height: 50px;
  width: 150px;
}
.secondary_action_btn:hover {
  border-color: #00d374 !important;
  color: #00d374;
}
.secondary_action_btn:focus {
  border-color: #00d374 !important;
  // color: #00d374 !important;
}
.card_input:hover {
  border-color: #e8e8e8 !important;
}
.green-btn:hover {
  color: #ffff;
}
.green-btn:focus {
  color: #fff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.45) !important;
}
// .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-icon {
//   color: rgba(0, 0, 0, 0.45);

// }

.ant-steps-item-custom.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #00d374 !important;
}

.main_login_container {
  overflow-x: hidden;
  max-height: 100vh;
  overflow-y: scroll;
}
