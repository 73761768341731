@import '../../assets/scss/libs/vars';

.add-contact-modal {

  .modal-body {
    flex-direction: column;
    padding: 10px 0px;

    .main-info {
      width: 100%;
      margin-bottom: 20px;
    }

    .button {
      width: 100px;
      height: 40px;
      margin-top: 20px;
    }
  }
}