.dashboard-container .main-wrapper .card-reports {
  color: #7e77ff;
  border-left: 3px solid #7e77ff;
}

.badge-cards-icon {
  //   position: absolute;
  //   margin-left: 73px;
  background: #f0effe;
  border-radius: 35px;
  padding: 6px;
  text-align: center;
  line-height: 1.2;
  color: #7e77ff;
  font-weight: 600;
  //   top: -14px;
  // transform: translate(87px, 33px);
}
.badge-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.header-cards {
  border-bottom: none;
  color: black;
}

.dashboard-container .button .header-cards {
  border-radius: 0;
}

.dashboard-container .chart .circle-chart .description .label .icon-title {
  // width: 13px;
  // height: 13px;
  // border-radius: 50%;
  // margin-right: 5px;
  // display: inline-block;
  background-color: #7e77ff;
}
.title-card {
  color: #828282;
  font-size: 16px;
}
