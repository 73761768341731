@import "../../../assets/scss/libs/vars";

.login-container {
  height: 100vh;
  overflow: hidden;
  .main-container {
    min-height: 100vh;
    max-width: 500px;
    margin: auto;

    .input-row {
      box-shadow: 0px 0px 10px #eee;

      .email {
        border-radius: 4px 4px 0px 0px;
        border-bottom-color: white;

        &:hover {
          border-color: $primary-color;
          // box-shadow: 0px 0px 3px $primary-color;
        }

        &:focus {
          border-color: $primary-color;
        }
      }

      .email:hover ~ .password input {
        border-top-color: white;
      }

      .email:focus ~ .password input {
        border-top-color: white;
      }

      .password input {
        border-radius: 0px 0px 4px 4px;

        &:hover {
          border-color: $primary-color;
        }

        &:focus {
          border-color: $primary-color;
        }
      }
    }

    .reset {
      position: absolute;
      bottom: 60px;

      button {
        margin-left: 10px;
        padding: 0px;
      }
    }

    .button {
      margin: 10px 0px 100px 0px;
      height: 50px;
      width: 150px;
    }

    .error-text {
      padding: 10px 10px 0px 10px;
      min-height: 55px;
      width: 100%;

      & span::before {
        content: "*";
      }
    }

    @media screen and (max-width: 600px) {
      padding: 7em 0;
    }
  }
}
.auth_wrapper .signup_cta_btn {
  // margin-top: 5px;
  // margin-left: 10px;
  margin: 5px 10px 0px 10px;
}
.signup_cta_btn {
  border-color: #1ed46a !important;
  border-radius: 0px !important;
  color: #1ed46a !important;
}
