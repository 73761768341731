@import "../../assets/scss/libs/vars";

.templates-container {
  .table-content {
    .record {
      padding: 20px 0px;
      border-bottom: 1px solid #e3e4e8;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-child {
        border-bottom: none;
      }

      .client-name {
        margin-left: 10px;
      }

      span {
        color: black;
      }

      .actions {
        text-align: right;

        .icon {
          margin: 0px 10px;
        }
      }
    }
  }
}
