@import '../../../assets/scss/libs/vars';

.assignments-container {
  .table-content {
    padding: 20px 20px;

    .record {
      display: flex;
      align-items: center;
      padding: 10px 0px;
    }

    .green {
      background-color: rgba(131, 227, 99, 0.08);
      border-left: 5px solid #1ed46a;
      padding: 0px 20px;
      margin: 15px 0px;
    }

    .yellow {
      background-color: rgba(255, 203, 114, 0.08);
      border-left: 5px solid #ffcb72;
      padding: 0px 20px;
      margin: 15px 0px;
    }

    .red {
      background-color: rgba(255, 114, 114, 0.08);
      border-left: 5px solid #ff7272;
      padding: 0px 20px;
      margin: 15px 0px;
    }

    .dropdown {
      width: 150px;
    }
  }
}