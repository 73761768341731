@import "../../../assets/scss/libs/vars";

.detailed-claimant-container {
  .main {
    margin: 20px 50px;

    .first-content {
      width: 100%;
      margin: auto;
      padding: 30px 50px;
      background-color: white;
      border-radius: 10px;

      .row {
        margin: 15px 0px;
      }

      .title-row {
        flex-direction: row;

        .title {
          color: black;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 0px;
        }
      }

      .label {
        margin: 0px;
        width: 200px;
      }

      .text {
        color: black;
      }

      .divider {
        margin: 20px 0px;
      }

      .textButton {
        color: #0082ff;
        cursor: pointer;
      }
    }

    .second-content {
      width: 100%;
      margin: auto;
      margin-top: 20px;
      padding: 40px 50px 30px 50px;
      background-color: white;
      border-radius: 10px;

      .button {
        z-index: 10;
      }
      .select-ages-btn .ant-select-selection--single {
        z-index: 10;
        padding: 2px;
        background: #f2f2f2;
        color: #000;
      }

      .pagination-container {
        z-index: 10;
      }

      .table-header {
        border-top: none;
        padding: 0px 10px;
        background-color: white;
        min-height: 36px;
        box-shadow: none;
        border-bottom: 1px solid #e3e4e8;
      }

      .table-content {
        margin: 0px;
        padding: 0px 0px;

        .empty-icon {
          margin: 50px 0px;
        }

        .record {
          padding: 20px 10px;
          border-bottom: 1px solid #e3e4e8;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:last-child {
            border-bottom: none;
          }

          span {
            color: black;
          }

          .actions {
            text-align: right;

            .icon {
              margin: 0px 10px;
            }
          }
        }
      }

      .tab {
        margin-top: -40px;

        .badge-icon .ant-badge-count {
          margin-left: 10px;
          background-color: rgba(21, 18, 71, 0.08);
          color: black;
        }

        .selected-badge-icon .ant-badge-count {
          margin-left: 10px;
          background-color: rgba(0, 130, 255, 0.1);
          color: #0082ff;
        }

        .note-status {
          display: flex;
          align-items: center;

          .circle {
            width: 13px;
            height: 13px;
            border-radius: 50%;
            margin-right: 10px;
            display: inline-block;
          }

          .circle-pending {
            background-color: #72ccff;
          }

          .circle-completed {
            background-color: #1ed46a;
          }

          .circle-dismissed {
            background-color: #ff7272;
          }
        }
      }
    }
    .status-wrapper {
      display: block;
    }
    .status-rounded {
      background: red;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      display: inline-block;
    }
    .logo-uploader {
      color: white;
      border-radius: 5px;
      padding: 5px 10px !important;
      cursor: pointer;
    }
    &:hover {
      color: white;
    }
  }
}
