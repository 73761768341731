@import "../../../../assets/scss/libs/vars";

.msa-demographic-container {
  .main {
    margin: 20px 20px;

    .content {
      width: 100%;
      margin: auto;
      max-width: 800px;
      background-color: white;
      border-radius: 10px;
      padding: 50px 50px;

      .title {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 600;
        color: black;
      }

      .row {
        margin-bottom: 20px !important;
      }

      .divider {
        margin: 40px 0px;
      }

      .upload {
        border-width: 1px;
        border-color: $blue-color;
        border-style: dashed;
        height: 46px;
        cursor: pointer;

        span {
          color: $blue-color;
        }
      }

      .dropdown {
        .ant-select-selection--multiple {
          margin-top: -10px !important;
          padding: 7px 10px !important;
          min-height: 46px;
          height: auto;
        }
      }

      .signature-image {
        width: 100%;
        height: 44px;
      }
    }
  }
}

.add-icd-code-modal {
  .modal-body {
    .label {
      margin: 0px;
      margin-right: 10px;
      width: 100px;
      text-align: right;
    }
    .input-col {
      width: 330px;
    }
    .row {
      margin: 10px 0px;
    }
    .button {
      margin: 10px 20px 0px 0px;
    }
  }
}
