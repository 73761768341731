@import "../../assets/scss/libs/vars";

.msa-medicare-cost-table-container {
  min-width: 900px;

  .table-header {
    padding: 3px 10px;
    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 10px;
    min-height: 100%;
    align-items: stretch;

    .sub-group-header {
      height: 48%;
      display: flex;
      align-items: center;
    }

    .sub-header {
      height: 48%;
      display: flex;
      align-items: stretch;
      padding-top: 3px;
    }

    .ant-col {
      // word-break: break-all;
      text-align: center;
      padding: 3px;
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .table-content {
    margin: 0px;
    padding: 0px 10px;

    .empty-icon {
      margin: 50px 0px;
    }

    .record {
      padding: 0px 0px;
      border-bottom: 1px solid #e3e4e8;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-col {
        word-break: break-word;
        text-align: center;
        font-size: 10px;
        padding: 3px;
      }

      span {
        color: black;
        text-align: center;
      }

      .text-left {
        text-align: left;
      }

      .text-center {
        text-align: center;
      }

      .text-right {
        text-align: right;
      }

      .actions {
        text-align: center;
        min-width: 100px;

        .icon {
          margin: 0px 5px;

          &:last-child {
            margin: 0px 0px;
          }
        }
      }
    }
  }

  .action {
    width: 100px;
  }

  .comment {
    flex: auto;
  }

  .border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  .border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  .border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.position * {
  cursor: pointer;
}

.animate-up {
  background: #f0f2f5;
  -webkit-animation: slide-out-top 7.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-top 7.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2020-9-26 22:26:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
/* ----------------------------------------------
 * Generated by Animista on 2020-9-26 22:37:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}
.animate-down {
  background: #f0f2f5;
  -webkit-animation: slide-out-bottom 7.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-bottom 7.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2020-9-26 22:40:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
