@import "../../assets/scss/libs/vars";
.more_margin {
  margin-bottom: 0px !important;
}
.input-ssn {
  width: 100%;
  height: 46px;
  padding: 0px 15px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  font-size: 14px;

  &:hover,
  &:focus,
  &:active {
    border-color: $blue-color;
    box-shadow: none;
    outline: none;
  }
}
