@import '../../../../assets/scss/libs/vars';

.msa-document-upload-container {
  .main {
    margin: 20px 20px;

    .content {
      width: 100%;
      margin: auto;
      max-width: 800px;
      background-color: white;
      border-radius: 10px;
      padding: 50px 50px;

      .title {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 600;
        color: black;
      }

      .row {
        margin-bottom: 20px;
      }

      .divider {
        margin: 40px 0px;
      }

      .upload {
        border-width: 1px;
        border-color: $blue-color;
        border-style: dashed;
        height: 46px;
        cursor: pointer;
        
        span {
          color: $blue-color;
          
          .uploaded {
            color: $purple-color;
          }  
        }
      }

      .dropdown {
        .ant-select-selection--multiple {
          padding: 7px 10px !important;
          height: 46px;
        }
      }

      .signature-image {
        width: 100%;
        height: 44px;
      }

      .back-btn {
        position: absolute;
        right: 40px;
      }
    }  
  }
}