.title {
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.claim-info {
  margin-bottom: 30px;
}
.row-custom {
  flex-wrap: wrap;
}
.date-injury {
  flex-wrap: wrap;
  /* width: 100px; */
  width: "100%" !important;
  display: flex;
  flex-direction: row;
}
