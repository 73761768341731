@import '../../../assets/scss/libs/vars';

.checkEmail-container {
  .main-container {
    min-height: 100vh;
    max-width: 600px;
    margin: auto;

    .label-group {
      text-align: center;
      margin-bottom: 150px;

      .label-email {
        color: $primary-color
      }
    }

    .check-icon {
      margin-bottom: 30px;
    }

    .reset {
      position: absolute;
      bottom: 60px;

      button {
        margin-left: 10px;
        padding: 0px;
      }
    }
  
    .button {
      margin: 50px 0px 50px 0px;
      height: 50px;
      width: 150px;
    }
  
    @media screen and (max-width: 600px) {
      padding: 4em 0;
    }
  }
}