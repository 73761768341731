.modal_fields {
  background: #f2f2f2 !important;
}
.dropdown_modal {
  padding: 15px 62px;
  background: #f2f2f2;
  color: #d8d8d8;
}
.ant-modal-title {
  text-align: center;
  font-weight: bold;
}
.content_title {
  position: relative;
  bottom: 15px;
}
.submit_modal_btn {
  margin-top: 38px;
  width: 20%;
  background: #1ed46a !important;
  border-color: #1ed46a !important;
  border-radius: 2px !important;
}
