@import '../../../../assets/scss/libs/vars';

.msa-calculation-table-container {
  .main {
    margin: 20px 20px;

    .content {
      width: 100%;
      margin: auto;
      background-color: white;
      border-radius: 10px;
      padding: 50px 30px;

      .title {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 600;
        color: black;
      }

      .sub-title {
        padding: 20px 0px;

        span {
          font-size: 14px;
          font-weight: 600;
          color: black;
        }
      }

      .row {
        margin-bottom: 20px;
      }

      .section {
        margin-bottom: 50px;
      }

      .table-header {
        margin-top: 30px;
        padding: 5px 0px;
        background-color: white;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 13px;
    
        .ant-col {      
          overflow-wrap: break-word;
          text-align: center;
          padding: 3px;
          border-right: 1px solid #e3e4e8;

          &:last-child {
            border-right: none;
          }
        }
      }
    
      .table-content {
        margin: 0px;
        padding: 0px 0px;

        .section-title {
          text-align: center;
          margin: 15px 0px;

          span {
            font-size: 14px;
            font-weight: 600;
            color: black;
          }          
        }
    
        .empty-icon {
          margin: 50px 0px;
        }
    
        .record {
          padding: 5px 0px;
          border-bottom: 1px solid #e3e4e8;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:first-child {
            border-top: 1px solid #e3e4e8;
          }
    
          .ant-col {      
            overflow-wrap: break-word;
            text-align: center;
            padding: 3px;
            border-right: 1px solid #e3e4e8;

            &:last-child {
              border-right: none;
            }
          }

          .text-left {
            text-align: left;
          }

          .text-right {
            text-align: right;
          }
    
          span {
            color: black;
            text-align: center;
          }
        }
      }

      .divider {
        margin: 20px 0px;
        background-color: white;
      }
    }  
  }
}