@import '../../assets/scss/libs/vars';

.tags-input-box-container {
  .label {
    margin-bottom: 10px;
  }
  
  .editable-content {
    min-height: 46px;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 12px 15px;
  
    &:hover {
      border-color: #40a9ff;
    }
  
    &:focus {
      border-color: #40a9ff;
      box-shadow: none;
      outline: none;
    }
  }
}